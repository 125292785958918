@import '../../variables.scss';

.product-card{
    border: 0;

    .card-body{
        padding: 0;
        position: relative;

        img{
            border-radius: 12px;
        }

        .product-overlay{
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0;
            width: 100%;
            border-radius: 12px;
            background: rgba(35, 35, 35, 0.60);
            display: none;

            a{
                color: $light;
                font-family: $poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                margin: 0;
                text-decoration: none;
            }
        }
    }

    .card-footer{
        background: transparent;
        border: 0;

        .card-text{
            color: $gray;
            font-family: $poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.217px;
        }
    }

    &:hover .product-overlay{
        display: flex;
    }
}

// Screen width higher than 992px
@media (min-width: 992px) {
    .product-card{
        margin: 15px;

        .card-footer{    
            .card-text{
                font-size: 14px;
            }
        }
    }
}  