@import '../../variables.scss';

#quoteInfoSection{
    padding: 58px 0;

    h4{
        color: $dark;
        font-family: $poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        text-align: center;
    }

    p{
        color: $gray;
        text-align: justify;
        font-family: $poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
    }

    form{
        .input-group{
            border-radius: 6px;
            border: 1px solid $light-gray;

            .input-group-text{
                color: rgba(77, 77, 77, 0.5);
            }
        }

        .form-control{
            border-radius: 6px;
            border: 1px solid $light-gray;
        }

        input[type="email"].form-control,
        input[type="text"].form-control,
        input[type="tel"].form-control,
        .input-group-text {
            background: transparent;
            border: 0;
        }        

        button{
            border-radius: 56px;
            background: $green-200;
            border: 0;

            &:hover{
                background-color: $green-300;
            }
        }
    }
}

// Screen width higher than 768px
@media (min-width: 768px) {
    #quoteInfoSection{
        .row:first-child{
            flex-direction: row-reverse;
        }

        h4{
            text-align: left;
            font-size: 24px;
        }

        p{
            text-align: left;
            font-size: 16px;
        }
    }
}