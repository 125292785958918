@import '../../variables.scss';

#contactInfoSection{
    padding: 58px 0;

    h4{
        color: $dark;
        font-family: $poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
    }

    p{
        color: $gray;
        text-align: justify;
        font-family: $poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    form{
        .input-group{
            border-radius: 6px;
            border: 1px solid $light-gray;

            .input-group-text{
                color: rgba(77, 77, 77, 0.5);
            }
        }

        .form-control{
            border-radius: 6px;
            border: 1px solid $light-gray;
        }

        input[type="email"].form-control,
        input[type="text"].form-control,
        .input-group-text {
            background: transparent;
            border: 0;
        }        

        button{
            border-radius: 56px;
            background-color: $green-200;
            border: 0;

            &:hover{
                background-color: $green-300;
            }
        }
    }
    ul{
        list-style-type: none;
        margin-top: 25px;

        li{
            color: $gray;
            font-family: $poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.217px;
            margin-bottom: 15px;

            a{
                color: $gray;
            }

            img{
                width: 28px;
            }

            span:nth-child(2){
                margin-left: 8px;
                font-size: 12px;
            }
        }
    }
}

// Screen width higher than 768px
@media (min-width: 768px) {
    #contactInfoSection{
        .row:nth-child(1){
            flex-direction: row-reverse;
        }

        ul{    
            li{    
                span:nth-child(2){
                    font-size: 16px;
                }
            }
        }
    }
}