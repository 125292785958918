@import '../../variables.scss';

#introSection{
    padding: 60px 0 70px 0;

    h4{
        color: $green-300;
        font-family: $poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h6{
        color: $gray;
        font-family: $poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p{
        color: $gray;
        text-align: justify;
        font-family: $poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

// Screen width higher than 768px
@media (min-width: 768px) {
    #introSection{
        .row{
            flex-direction: row-reverse;
        }
    }
}