@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Josefin+Sans:wght@700&family=Poppins&display=swap');
@import './variables.scss';

a{
    text-decoration: none;
}

// Banner section styling
#banner {
    position: relative;
    top: 0;
    width: 100%;
    height: 250px;

    img{
        object-fit: cover;
        height: 100%;
    }

    .banner-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $light;
    }

    h1 {
        color: $light;
        font-family: $josefin-sans;
        white-space: nowrap;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        transform: translate(0px, 25px);
        margin: 0;
    }

    /* Add the overlay */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
}
 
// Screen width higher than 1200px
@media (min-width: 1200px) {
    #banner{
        h1{
            font-size: 30px; 
        }
    }
}