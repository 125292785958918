@import '../../variables.scss';

// Home banner section styling
#homeBanner {
  position: relative;
  // top: 0;
  width: 100%;
  height: 100vh;

  img{
    height: 100%;
    object-fit: cover;
  }

  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $light;
  }

  h1 {
    font-size: 2.5rem;
    color: $light;
    font-family: $josefin-sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: $light;
    font-family: $dm-serif-display;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  button {
    color: $light;
    font-family: $poppins;
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 56px;

    &:hover {
      background-color: $green-200;
      border: 0;
    }
  }

  /* Add the overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

// Service cards section styling
#serviceCards{
  padding: 40px 0px;
}

// Brands section styling
#brandSection{
  text-align: center;

  h5{
    color: $gray;
    font-family: $dm-serif-display;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }

  .slick-slider{
    margin: 32px 0;
  }

  .slick-arrow{
    display: none!important;
  }
}

// Product section styling
#productSection{  
  h5{
    color: #1B7935;
    font-family: $dm-serif-display;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h4{
    color: $gray;
    font-family: $dm-serif-display;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    text-align: center;
  }

  p{
    color: var(--neutral-grey, #717171);
    text-align: justify;
    font-family: $poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  .productRow{
    padding: 24px;
    align-items: center;
  }

  .productRow:nth-child(even){
    flex-direction: column-reverse;
  }
}

// Testimonials section styling
#testimonialSection{
  margin-bottom: 100px;
  
  h5{
    color: #1B7935;
    font-family: $dm-serif-display;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .slick-arrow{
    display: none!important;
  }
}

// Screen width higher than 768px
@media (min-width: 768px) { 
  #testimonialSection, #productSection{
    h5{
      font-size: 1.8em;
    }
  }

  #productSection{
    h4{
      text-align: left;
    }

    .productRow:nth-child(even){
      flex-direction: row;
    }
  }
}

// Screen width higher than 992px
@media (min-width: 992px) {
  #testimonialSection, #productSection{
    h5{
      font-size: 2.2em;
    }
  }

  #testimonialSection{
    padding: 5px 0;
  }
}