@import '../../variables.scss';

.service-card{
    border-radius: 8px;
    border: 1px solid rgba(182, 182, 182, 0.40);
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.20);
    min-height: 255px;

    .card-body{
        padding: 38px 32px 24px;
    }

    .card-title{
        color: $gray;
        margin: 0;
        line-height: 32px;
        font-size: 24px;
        font-weight: 700;
        font-family: $poppins;
    }

    .card-text{
        line-height: 20px;
        font-family: $poppins;
        font-size: 14px;
        font-weight: 400;
    }

    .service-icon{
        border-radius: 18px 5px 10px 5px;
        background: rgba(27, 121, 53, 0.12);
        width: 50px;
        height: 49px;
        flex-shrink: 0;
        margin: 0 auto;
        margin-bottom: 16px;

        img{
            transform: translate(-19px, -15px);
        }
    }

    &:hover {
        box-shadow: 4px 4px 8px 0px rgba(182, 182, 182, 0.25);
        
        .service-icon img{
            transform: translate(0px, 1px);
            transition: all .3s;
        }
    }
}