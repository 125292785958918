@import './variables.scss';

.text-light{
    color: $light-gray;
}

.offcanvas{
    background-color: $green-400;
    width: 75%!important;
    color: $light;

    a{
        border-bottom: 1px solid $gray;
        margin-bottom: 15px;
    }
}

// Screen width higher than 768px
@media (min-width: 768px) { 
    .offcanvas{
        a{
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
  }