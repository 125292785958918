@import '../../variables.scss';

.testimonial-card{
    width: 90%;
    min-height: 300px;

    .card-header{
        background: transparent;
        display: flex;
        border: 0;
        align-items: center;

        img:first-child{
            width: 50px;
            height: 50px;
        }
        
        div{
            margin-left: 15px;
            text-align: left;

            .card-title{
                color: $gray;
                font-family: $poppins;
                font-size: .8em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .card-body{
        text-align: left;
        padding: 16px;

        .card-text{
            color: $gray;
            font-family: $poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

// Screen width higher than 768px
@media (min-width: 768px) {
    .testimonial-card{
        min-height: 350px;
    }
}

// Screen width higher than 1200px
@media (min-width: 1200px) {
    .testimonial-card{
        min-height: 250px;
    }
}