@import '../../variables.scss';

header{
    background: rgba(35, 35, 35, 0.80);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    img{
        width: 110px;
    }

    .navbar-nav{
        .nav-link{
            color: $light;
            font-family: $poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            margin: 0 12px;
    
            &:hover{
                color: $green-200!important;
            }
    
            &:visited{
                color: $light;
            }
    
            &:last-child{
                margin-right: 0;
            }

            &.active {
                color: $green-200!important;
            }
        }
    }
}

// Screen width higher than 768px
@media (min-width: 768px) {
    header{
        img{
            width: 125px;
        }
    }
}

// Screen width higher than 992px
@media (min-width: 992px) {
    header{
        img{
            width: 206px;
        }
    }
}