@import '../../variables.scss';

footer {
    padding: 25px 0;
    background-color: $green-400;
    color: #F5F7FA;

    .footer-left{
        p{
            color: #F5F7FA;
            font-family: $poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .footer-right{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        ul{
            list-style-type: none;
            display: flex;
            padding: 0;
            margin: 0;
        }

        .nav-links{
            li{
                color: $light;
                font-family: $poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                margin-left: 10px;
            }

            li:first-child{
                margin-left: 0;
            }
        }
        
        .social-links li{
            background: rgb(77 77 77 / 37%);;
            width: 32px;
            border-radius: 50px;
            height: 32px;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            padding: 0;
            margin-left: 5px;

            a{
                color: $light;
            }
        }
    }
}

@media (max-width: 768px) { 
    footer .row{
        flex-direction: column-reverse;
    }
}

@media (min-width: 992px) { 
    footer .footer-right {
        justify-content: end;
        margin-bottom: 0;
        
        li{
            margin-right: 10px;
        }
    }
}