// Colors
$light: #FAFAFA;
$gray: #4D4D4D;
$light-gray: #B6B6B6;
$dark: #232323;
$green-100: #93D555;
$green-200: #4CAF4F;
$green-300: #019444;
$green-400: #316848;

// Fonts
$josefin-sans: 'Josefin Sans';
$dm-serif-display: 'DM Serif Display';
$poppins: 'Poppins';